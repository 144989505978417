html, body, #root, .wrapper {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.page-temp-fix {
    display:flex;
    flex-direction: column;
    margin-top: 60px;
    height: calc(100% - 60px);
}



button {
    margin: 10px;
    border: none;
    border-radius: 4px;
    background-color: #ccc;
    color: #000000;
    cursor: pointer;
}



.card-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
    width: calc(100vw / 3);
    max-width: 33%;
    margin: 0 auto;
    margin-top: 150px;
}

.custom-card {
    background-color: #f4f4f4;
    padding: 0px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    cursor: pointer;
    width:70vw;
    margin-bottom: 10px;
}
  
.custom-card:hover {
    background-color: #eaeaea;
}
  
.custom-card:active {
    background-color: #d4d4d4;
}  

.card-header {
    background-color: rgb(182, 186, 189);
    border-radius: 4px;
}

.popup {
    position: fixed;
    top: 55px;
    left: -34vw ; /* Start the sidebar off-screen */
    width: 33vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 999;
    transform: translateX(0);
    transition: transform 0.3s ease-out;
    border: 1px solid #333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
  

.sample-information-page{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.sample-information-header{
    border-bottom: 1px solid #000000;
    display: flex;
    align-items: center;
}

.sample-information-header button{
    height: 30px;
}

.sample-information-header h1 {
    padding-left: 60px;
}

.sample-information-menu{
    background-color: #314859;
    height: 100%;
    color:#E6E6E6;
    width:300px;
}
.sample-information-menu p{
    width:300px;
}

.sample-information-menu p.selected{
    background-color: #18242c;
    cursor:pointer;
    width: 280px;
    color:#1a8fe9;
    font-weight: bold;
}
.sample-information-menu p:hover {
    background-color: #18242c;
    cursor:pointer;
    width: 280px;
}
  
.view-container{
    flex:2;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    padding:0;
    margin:0;
    background-color: #e9e9e9;
    overflow: auto;
}
  .listItem {
    position: relative;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  
  .listItem::before,
  .listItem::after {
    content: '';
    position: absolute;
    width: 300px;
    left: 0;
    right: 0;
    height: 1px; /* Adjust the height of the lines as needed */
    background-color: #333; /* Adjust the color of the lines as needed */
  }
  
  .listItem::before {
    top: 0;
  }
  
  .listItem::after {
    bottom: 0;
  }

.sample-information-blurb {
    width:600px;
    display:flex;
    align-items: center;
}

.sample-information-blurb h3{
    width:200px;
    flex-shrink: 0;
}

.sample-information-blurb p{
    width: 400px;
    flex-shrink: 0;
}

.table-constraint{
    max-width: 100%;
}

.information-container {
    display:grid;
    grid-template-columns: 1fr 1fr 2fr;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  
}

.input-alignment {
    display:flex;
    justify-content: flex-start;
    padding:15px;
}

.input-alignment-item-left {
    justify-content: flex-start;
    min-width: 300px;
}

.input-alignment-item-right {
    justify-content: flex-start;
    min-width: 800px;
    max-width: 800px;
}


.input-alignment label{
    text-align: left;
}

.primer-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .primer-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

.sample-information-menu-header{
    display: flex;
}


.loading-position{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
}