.oa-tabs{
    display:flex;
    border-bottom: 6px solid #f0f0f0;
    margin-bottom: 10px;
    margin-top: 30px;
}

.oa-tab{
    padding: 10px;
    margin: 0;
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
}

.oa-tab:hover {
  background-color: #f0f0f0;
}

.oa-tab.selected {
  background-color: #f0f0f0;
}

.page-input {
  margin: 10px;
  max-width:30px;
  max-height:30px;
}