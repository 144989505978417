.rounded-div-genome-info {
    display: flex;
    flex-direction: column;
    border: 1px solid #5b5b5b;
    border-radius: 10px;
    padding: 7px;
    background-color: #f2f2f2;
    max-width: 800px;
}

.info-container {
  display: flex;
  width: max-content;
}

.genome-table {
  margin: 0 auto;
  justify-content: center;
}

.genome-table th,
.genome-table td {
  padding: 10px;
  text-align: left;
  max-width:150px;
  width:150px;
  word-wrap: break-word;
}


.genome-table table {
  width: 100%;
  border-collapse: collapse;
}

.genome-table th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.genome-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.genome-table td {
  overflow: hidden;
  text-overflow: ellipsis; 
}

.genome-table tr:hover {
  background-color: #f5f5f5;
}

.input-alignment-small {
  display:flex;
  justify-content: flex-start;
  align-items: center;
}


.input-alignment-item-small {
  display:flex;
  justify-content: flex-start;
  min-width: 200px;
  align-items: center;
  max-width:800px;
}

.input-alignment-item-small-label {
  justify-content: flex-start;
  min-width: 200px;
  height:10px;
  align-items: center;
  font-weight: bold;
}

.genome-container{
  display: inline-block;
  margin:5px;
}

.genome-table-buttons {
  display: flex;
  justify-content: space-between;
}


.genome-table-buttons > div {
  display: flex;
}

.genome-table-buttons > div:first-child {
  justify-content: flex-start;
}

.genome-table-buttons > div:last-child {
  justify-content: flex-end;
}

.genome-table-buttons button {
  margin: 10px;
  width:60px;
  height:30px;
}

.page-input {
    margin: 10px;
    max-width:30px;
    max-height:30px;
}

.genome-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Applies a blur effect to the background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .genome-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1; /* Make sure the popup content is above the background */
  }