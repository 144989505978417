.genome-table {
    justify-content: left;
    width: 1000px;
  }

.genome-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.genome-table th,
.genome-table td {
  padding: 10px;
  text-align: left;
  max-width:200px;
  width:200px;
  word-wrap: break-word;
  border-bottom: 1px solid #ddd;
}

.genome-table th:first-child,
.genome-table td:first-child {
    width: 20px; 
}

.genome-table tr:hover {
  background-color: #f5f5f5;
}

.genome-table-container {
  display: inline-block;
  justify-content: flex-start;
  flex-direction: column;
}

.genome-sanger-container{
  border-top: 2px solid #f5f5f5;
  padding-top: 20px;
}

.genome-table-buttons {
  display: flex;
  justify-content: space-between;
}

.genome-table-buttons > div {
  display: flex;
}

.genome-table-buttons > div:first-child {
  justify-content: flex-start;
}

.genome-table-buttons > div:last-child {
  justify-content: flex-end;
}

.genome-table-buttons button {
  margin: 10px;
  min-width:60px;
  min-height:30px;
}

.genome-table-large-button {
  margin: 10px;
  min-width:60px;
  min-height:30px;
}

.page-input {
  margin: 10px;
  max-width:30px;
  max-height:30px;
}