.Login {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: column; /* Display children vertically */
    height: 100vh; /* Span entire screen */
    text-align: center; /* Center Elements */
    background-color: #e9e9e9
}

.login-form{
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding-top: 10px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-left: 50px;
}

.login-form p{
    margin:0;
    text-align: left;
}

.login-form input{
    margin-bottom: 20px;
}