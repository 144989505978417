.container {
  border:2px #ccc solid;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin:10px;
  width:99%;
  background-color: white;
  padding:10px;
}

.genome-tabs{
    display:flex;
    border-bottom: 6px solid #f0f0f0;
}

.genome-tab{
    padding: 10px;
    margin: 0;
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
}

.genome-tab:hover {
  background-color: #f0f0f0;
}

.genome-tab.selected {
  background-color: #f0f0f0;
}