.rounded-div-primerpair-info {
    display: flex;
    flex-direction: column;
    border: 1px solid #5b5b5b;
    border-radius: 10px;
    padding: 7px;
    background-color: #f2f2f2;
    max-width: 800px;
}

.info-container {
  display: flex;
  width: max-content;
}

.primerpair-table {
  margin: 0 auto;
  justify-content: center;
}

.primerpair-table th,
.primerpair-table td {
  padding: 10px;
  text-align: left;
  max-width:150px;
  width:150px;
  word-wrap: break-word;
}


.primerpair-table table {
  width: 100%;
  border-collapse: collapse;
}

.primerpair-table th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.primerpair-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.primerpair-table td {
  overflow: hidden;
  text-overflow: ellipsis; 
}

.primerpair-table tr:hover {
  background-color: #f5f5f5;
}

.input-alignment-small {
  display:flex;
  justify-content: flex-start;
  align-items: center;
}


.input-alignment-item-small {
  display:flex;
  justify-content: flex-start;
  min-width: 200px;
  align-items: center;
  max-width:800px;
}

.input-alignment-item-small-label {
  justify-content: flex-start;
  min-width: 200px;
  height:10px;
  align-items: center;
  font-weight: bold;
}

.primer-container{
  display: inline-block;
  margin:5px;
}

.primer-table-buttons {
  display: flex;
  justify-content: space-between;
}


.primer-table-buttons > div {
  display: flex;
}

.primer-table-buttons > div:first-child {
  justify-content: flex-start;
}

.primer-table-buttons > div:last-child {
  justify-content: flex-end;
}

.primer-table-buttons button {
  margin: 10px;
  width:60px;
  height:30px;
}

.page-input {
  margin: 10px;
  max-width:30px;
  max-height:30px;
}