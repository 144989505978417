.main-search-bar {
    position: flex;
    justify-content: center;
    flex-direction: column;
    top: 0;
    width: 100%;
    padding: 10px;
}

.result-list{
    justify-content:center;
}

.result-item {
    display: inline-block;
    text-align: left;
    border-radius: 10px;
    width:600px;
    border: 1px solid #e6e6e6;
    margin: 5px;
    padding: 4px;
}

.result-item:hover {
    background-color: #f4f4f4; 
}

.result-container{
    width:50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.search-area{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.result-metadata{
    display:flex;
    margin-left: 15px;
}

.filter-indicator{
    display:flex;
    font-weight: bold;
    color: red;
}