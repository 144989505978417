.container {
    border:2px #ccc solid;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin:10px;
    width:99%;
    background-color: white;
    padding:10px;
  }
  
  .activity-table {
    width:1000px;
    }
  
    .activity-table th {
        background-color: #f2f2f2;
        font-weight: bold;
      }
  
  .activity-table th,
  .activity-table td {
    padding: 10px;
    text-align: left;
    max-width:200px;
    width:200px;
    word-wrap: break-word;
    border-bottom: 1px solid #ddd;
  }
  
  .activity-table th:first-child,
  .activity-table td:first-child {
      width: 20px; 
  }
  
  .activity-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .activity-table-container {
    display: inline-block;
    flex-direction: column;
  }
  
  
  .activity-table-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  
  .activity-table-buttons > div {
    display: flex;
  }
  
  .activity-table-buttons > div:first-child {
    justify-content: flex-start;
  }
  
  .activity-table-buttons > div:last-child {
    justify-content: flex-end;
  }
  
  .activity-table-buttons button {
    margin: 10px;
    width:60px;
    height:30px;
  }
  
  .page-input {
    margin: 10px;
    max-width:30px;
    max-height:30px;
  }
  