.new-sample-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Applies a blur effect to the background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .new-sample-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1; /* Make sure the popup content is above the background */
  }

  .page-input {
    margin: 10px;
    max-width:30px;
    max-height:30px;
}