.container {
  border:2px #ccc solid;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin:10px;
  width:99%;
  background-color: white;
  padding:10px;
}

.community-table {
    justify-content: left;
    width: 1000px;
  }

.community-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.community-table th,
.community-table td {
  padding: 10px;
  text-align: left;
  max-width:200px;
  width:200px;
  word-wrap: break-word;
  border-bottom: 1px solid #ddd;
}

.community-table th:first-child,
.community-table td:first-child {
    width: 20px; 
}

.community-table tr:hover {
  background-color: #f5f5f5;
}

.community-table-container {
  display: inline-block;
  justify-content: flex-start;
  flex-direction: column;
}


.community-table-buttons {
  display: flex;
  justify-content: space-between;
}


.community-table-buttons > div {
  display: flex;
}

.community-table-buttons > div:first-child {
  justify-content: flex-start;
}

.community-table-buttons > div:last-child {
  justify-content: flex-end;
}

.community-table-buttons button {
  margin: 10px;
  width:60px;
  height:30px;
}

.page-input {
  margin: 10px;
  max-width:30px;
  max-height:30px;
}