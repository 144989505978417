.sample-information-fields {
    display: flex;
    justify-content:flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.container {
    border:2px #ccc solid;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin:10px;
    width:99%;
    background-color: white;
}