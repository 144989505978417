.ToolBar {
    display: flex;
    background-color: #29648A;
    width: 100vw;
    padding: 10px; /* Padding for spacing inside the header */
    position: fixed;
    top: 0;
    height: 40px;
    justify-content: space-between; /* Add space between left and right containers */
    align-items: center; /* Vertically center the buttons */
    z-index: 2;
}

.ToolBar button {
    color: white;
}

.ToolBar button:hover {
    color: #aca6a6;
}

.left-buttons-container {
    padding: 14px 16px;
    display: relative;
    left: 0;
}

.right-buttons-container {
    padding: 14px 16px;
    display: relative;
    right: 0;
}

.clear-button {
    background: none;
    border: none;
    font-size: 17px;
}