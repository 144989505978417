
.popup.open {
    transform: translateX(34vw); /* Slide the sidebar into view */
}

.popup-layout {
    display: grid;
    grid-template-rows: 1fr; /* Single row */
    grid-auto-flow: column; /* Items flow horizontally */
    grid-gap: 20px; /* Gap between grid items */
}

.popup-exit-button {
    position: absolute;
    top: 0;
    right: 0;
}

.popup-option-list{
    display: grid;
    grid-template-columns: 1fr; /* Three columns with different widths */
    grid-gap: 10px; /* Gap between grid items */
    padding-top: 3vh;
    padding-left: 1vh;
    justify-items: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popup-option{
    display:flex;
    align-items: center;
    align-self: flex-end;
}

.popup-option p {
    margin-right: 10px;
    flex:1;
  }
  
.popup-option input[type="date"] {
    width: 150px;
}

.popup-option select{
    width:5vw;
    justify-content:flex-end;
    flex:1;
}

.popup-label{
    min-width: 100px;;
}